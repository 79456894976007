<template>
  <page-content>
    <p class="header">
      订单信息
    </p>
    <table class="detail-form">
      <tr>
        <td>订单状态：{{ ORDER_STATUS_MAP[tradeOrderVo.orderStatus] }}</td>
      </tr>
      <tr>
        <td>供应商名称：{{ tradeOrderVo.supplierName }}</td>
        <td>店铺名称：{{ tradeOrderVo.storeName }}</td>
        <td>订单总额：{{ tradeOrderVo.totalAmount }}元</td>
        <td>实付金额：{{ tradeOrderVo.paymet }}元</td>
      </tr>
      <tr>
        <td>创建时间：{{ dayjs(tradeOrderVo.createTime).format('YYYY-MM-DD HH:mm:ss') }}</td>
        <td v-if="tradeOrderVo.payName">
          支付方式：{{ tradeOrderVo.payName }}
        </td>
        <td v-if="tradeOrderVo.payTime">
          支付时间：{{ dayjs(tradeOrderVo.payTime).format('YYYY-MM-DD HH:mm:ss') }}
        </td>
      </tr>
      <tr>
        <td>
          <p>平台券/商品券：{{ tradeOrderVo.couponFee }}元</p>
          <p v-if="tradeOrderVo.platCouponId">
            （ID:{{ tradeOrderVo.platCouponId }}）
          </p>
        </td>
        <td>
          <p>商家券/商品券：{{ tradeOrderVo.discountFee }}元</p>
          <p v-if="tradeOrderVo.storeCouponId">
            （ID:{{ tradeOrderVo.storeCouponId }}）
          </p>
        </td>
        <td>
          <p>活动优惠：{{ tradeOrderVo.promFee }}元</p>
          <!--          <p>（秒杀活动:没有！！！）</p>-->
        </td>
      </tr>
    </table>
    <p class="header">
      商品信息
    </p>
    <div class="detail-form">
      <span>订单编号：{{ tradeOrderVo.orderNo }}</span>
      <el-button
        type="text"
        size="mini"
        style="margin-left: 20px"
        @click="handleClipboard($event)"
      >
        复制
      </el-button>
    </div>
    <el-table
      :data="goodsData"
      border
      :header-row-style="{'font-weight': 100, 'color': '#0D0202'}"
      :row-style="{'font-size': '14px', 'color': '#0D0202'}"
      :header-cell-style="{'font-weight': 'bold', 'background': '#F0F2F8'}"
    >
      <el-table-column label="商品信息">
        <template slot-scope="{row}">
          <div class="goods">
            <img :src="row.goodsImage.split(',')[0]" />
            <div>
              <p>{{ row.goodsName }}</p>
              <p class="id">
                ID：{{ row.goodsId }}
              </p>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="skuName"
        label="规格"
      ></el-table-column>
      <el-table-column
        prop="sellCount"
        label="数量"
      ></el-table-column>
      <el-table-column
        prop="sellPrice"
        label="商品单价（元）"
      ></el-table-column>
      <el-table-column
        prop="sellTotal"
        label="商品总价（元）"
      ></el-table-column>
      <el-table-column
        prop="discountFee"
        label="店铺优惠（元）"
      ></el-table-column>
      <el-table-column label="商家实收（元）">
        <template slot-scope="{row}">
          <p>{{ row.supplierPayment }}</p>
          <p style="color: #736E6E;">
            含配送费：{{ row.postFee }}
          </p>
          <p style="color: #736E6E;">
            平台优惠：{{ row.couponFee }}
          </p>
        </template>
      </el-table-column>
      <el-table-column label="订单状态">
        <template slot-scope="{row}">
          <span>{{ GOODS_STATUS_MAP[row.subOrderStatus] || '无' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="售后状态">
        <template slot-scope="{row}">
          <span>{{ REFUND_STATUS_MAP[row.refundStatus] || '无' }}</span>
        </template>
      </el-table-column>
    </el-table>
    <p class="detail-form">
      买家留言：{{ tradeOrderVo.buyerMomo || '无' }}
    </p>
    <p
      v-if="tradeOrderVo.orderType === 2"
      class="header"
    >
      入住人信息
    </p>
    <el-table
      v-if="tradeOrderVo.orderType === 2"
      :data="hotelData"
      border
      :header-row-style="{'font-weight': 100, 'color': '#0D0202'}"
      :row-style="{'font-size': '14px', 'color': '#0D0202'}"
      :header-cell-style="{'font-weight': 'bold', 'background': '#F0F2F8'}"
    >
      <el-table-column
        prop="identityName"
        label="入住人"
      ></el-table-column>
      <el-table-column
        prop="mobile"
        label="联系方式"
      ></el-table-column>
      <el-table-column
        prop="identityNo"
        label="身份证"
      ></el-table-column>
      <el-table-column
        prop="personNum"
        label="入住人数"
      ></el-table-column>
    </el-table>

    <p class="header">
      售后信息
    </p>
    <el-table
      :data="refundData"
      border
      :header-row-style="{'font-weight': 100, 'color': '#0D0202'}"
      :row-style="{'font-size': '14px', 'color': '#0D0202'}"
      :header-cell-style="{'font-weight': 'bold', 'background': '#F0F2F8'}"
    >
      <el-table-column
        prop="refundNo"
        label="退款编号"
      ></el-table-column>
      <el-table-column
        prop="applyRefundFee"
        label="申请退款金额（元）"
      ></el-table-column>
      <el-table-column
        prop="refundFee"
        label="实际退款金额（元）"
      ></el-table-column>
      <el-table-column label="申请时间">
        <template slot-scope="{row}">
          <p v-if="row.createTime">
            {{ dayjs(row.createTime).format('YYYY-MM-DD HH:mm:ss') }}
          </p>
        </template>
      </el-table-column>
      <el-table-column
        prop="reason"
        label="退款原因"
      >
        <template slot-scope="{row}">
          <span>{{ REFUND_REASON_MAP[row.reason] }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="reasonDescription"
        label="补充原因"
      ></el-table-column>
      <el-table-column label="图片">
        <template slot-scope="{row}">
          <div style="display: flex"></div>
          <el-image
            v-for="img in row.imageUrls"
            :key="img"
            :src="img"
            style="width: 40px;height: 40px"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column label="售后状态">
        <template slot-scope="{row}">
          <el-button
            type="text"
            @click="handleRefundDetail(row)"
          >
            {{ REFUND_STATUS_MAP[row.refundStatus] }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="header">
      备注
      <span class="tip">订单备注由商家添加，仅平台客服和商家可见</span>
    </div>
    <p
      v-for="remark in remarkList"
      :key="remark.createTime"
      class="remark"
    >
      {{ remark.memo }}
    </p>
    <el-input
      v-show="isTipsEdit"
      v-model="tips"
      type="textarea"
      autosize
      style="margin-bottom: 10px"
      placeholder="请输入"
    >
    </el-input>
    <el-button
      v-show="!isTipsEdit"
      type="primary"
      size="medium"
      @click="isTipsEdit=true"
    >
      添加备注
    </el-button>
    <el-button
      v-show="isTipsEdit"
      type="primary"
      size="medium"
      @click="handleRemark"
    >
      保存
    </el-button>
    <el-button
      v-show="isTipsEdit"
      size="medium"
      @click="isTipsEdit = false"
    >
      取消
    </el-button>
  </page-content>
</template>

<script>
import dayjs from 'dayjs'
import clipboard from '@/utils/clipboard'
import {
  ORDER_STATUS_MAP,
  REFUND_STATUS_MAP,
  LOGISTIC_STATUS_MAP,
  REFUND_REASON_MAP,
  GOODS_STATUS_MAP
} from './normals'
export default {
  name: 'HomestayDetail',
  data() {
    return {
      dayjs,
      ORDER_STATUS_MAP,
      REFUND_STATUS_MAP,
      LOGISTIC_STATUS_MAP,
      REFUND_REASON_MAP,
      GOODS_STATUS_MAP,
      goodsData: [],
      hotelData: [],
      refundData: [],
      tips: '',
      isTipsEdit: false,
      operateData: [],
      trackData: [],
      tradeOrderVo: {},
      remarkList: [],
      subOrderIdList: [],
      showDelivery: false,
      deliveryInfo: {
        logisticNo: '',
        logisticName: ''
      },
      logisticList: [],
      deliveryLoading: false
    }
  },
  computed: {
    orderId() {
      return this.$route.query.orderId
    }
  },
  mounted() {
    this.getDetail()
  },
  methods: {
    async getDetail() {
      const res = await this.$axios.get(this.$api.order.orderDetail, {
        params: {
          orderId: this.orderId
        }
      })
      this.tradeOrderVo = res.data.tradeOrderVo
      this.goodsData = res.data.tradeOrderGoodsVoList
      this.hotelData = [{
        identityName: res.data.tradeOrderHotelVo.identityName,
        mobile: res.data.tradeOrderHotelVo.mobile,
        identityNo: res.data.tradeOrderHotelVo.identityNo,
        personNum: res.data.tradeOrderHotelVo.personNum
      }]
      this.trackData = res.data.tradeOrderPackageDtoList
      this.remarkList = res.data.tradeOrderMemoDtoList
      this.refundData = res.data.tradeRefundDtoList
    },
    handleClipboard(event) {
      clipboard(this.tradeOrderVo.orderNo, event)
    },
    handleRemark() {
      this.$axios.post(this.$api.order.remark, {
        orderId: this.orderId,
        memo: this.tips
      }).then(res => {
        this.getDetail()
        this.isTipsEdit = false
      })
    },
    handleTrack({ logisticNo, logisticCode, logisticName }) {
      const routeData = this.$router.resolve({
        path: '/nb/order/refund/track',
        query: {
          logisticNo, logisticCode, logisticName
        }
      })
      window.open(routeData.href, '_blank')
    },
    handleRefundDetail(row) {
      const routeData = this.$router.resolve({
        path: '/nb/order/refund/detail',
        query: {
          refundId: row.refundId
        }
      })
      window.open(routeData.href, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  border-left: 3px solid #409EFF;
  padding: 12px;
  font-size: 18px;
  margin: 20px 0;
  .tip {
    font-size: 12px;
    color: #999
  }
}
.detail-form {
  margin-top: 10px;
  width: 100%;
  font-size: 14px;
  td {
    padding: 12px 16px;
  }
}
.order-tips {
  font-size: 14px;
  border: 1px solid #ebeef5;
  padding: 20px;
  text-align: center;
}
.goods{
  display: flex;
  img {
    width: 70px;
    height: 70px;
  }
  p {
    padding: 0 12px;
  }
  .id {
    color: #736E6E;
  }
}
.remark {
  padding: 12px 8px;
  border: 1px solid #dfe6ec;
  margin-bottom: 10px;
}
</style>
