var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "page-content",
    [
      _c("p", { staticClass: "header" }, [_vm._v(" 订单信息 ")]),
      _c("table", { staticClass: "detail-form" }, [
        _c("tr", [
          _c("td", [
            _vm._v(
              "订单状态：" +
                _vm._s(_vm.ORDER_STATUS_MAP[_vm.tradeOrderVo.orderStatus])
            ),
          ]),
        ]),
        _c("tr", [
          _c("td", [
            _vm._v("供应商名称：" + _vm._s(_vm.tradeOrderVo.supplierName)),
          ]),
          _c("td", [_vm._v("店铺名称：" + _vm._s(_vm.tradeOrderVo.storeName))]),
          _c("td", [
            _vm._v("订单总额：" + _vm._s(_vm.tradeOrderVo.totalAmount) + "元"),
          ]),
          _c("td", [
            _vm._v("实付金额：" + _vm._s(_vm.tradeOrderVo.paymet) + "元"),
          ]),
        ]),
        _c("tr", [
          _c("td", [
            _vm._v(
              "创建时间：" +
                _vm._s(
                  _vm
                    .dayjs(_vm.tradeOrderVo.createTime)
                    .format("YYYY-MM-DD HH:mm:ss")
                )
            ),
          ]),
          _vm.tradeOrderVo.payName
            ? _c("td", [
                _vm._v(" 支付方式：" + _vm._s(_vm.tradeOrderVo.payName) + " "),
              ])
            : _vm._e(),
          _vm.tradeOrderVo.payTime
            ? _c("td", [
                _vm._v(
                  " 支付时间：" +
                    _vm._s(
                      _vm
                        .dayjs(_vm.tradeOrderVo.payTime)
                        .format("YYYY-MM-DD HH:mm:ss")
                    ) +
                    " "
                ),
              ])
            : _vm._e(),
        ]),
        _c("tr", [
          _c("td", [
            _c("p", [
              _vm._v(
                "平台券/商品券：" + _vm._s(_vm.tradeOrderVo.couponFee) + "元"
              ),
            ]),
            _vm.tradeOrderVo.platCouponId
              ? _c("p", [
                  _vm._v(
                    " （ID:" + _vm._s(_vm.tradeOrderVo.platCouponId) + "） "
                  ),
                ])
              : _vm._e(),
          ]),
          _c("td", [
            _c("p", [
              _vm._v(
                "商家券/商品券：" + _vm._s(_vm.tradeOrderVo.discountFee) + "元"
              ),
            ]),
            _vm.tradeOrderVo.storeCouponId
              ? _c("p", [
                  _vm._v(
                    " （ID:" + _vm._s(_vm.tradeOrderVo.storeCouponId) + "） "
                  ),
                ])
              : _vm._e(),
          ]),
          _c("td", [
            _c("p", [
              _vm._v("活动优惠：" + _vm._s(_vm.tradeOrderVo.promFee) + "元"),
            ]),
          ]),
        ]),
      ]),
      _c("p", { staticClass: "header" }, [_vm._v(" 商品信息 ")]),
      _c(
        "div",
        { staticClass: "detail-form" },
        [
          _c("span", [_vm._v("订单编号：" + _vm._s(_vm.tradeOrderVo.orderNo))]),
          _c(
            "el-button",
            {
              staticStyle: { "margin-left": "20px" },
              attrs: { type: "text", size: "mini" },
              on: {
                click: function ($event) {
                  return _vm.handleClipboard($event)
                },
              },
            },
            [_vm._v(" 复制 ")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.goodsData,
            border: "",
            "header-row-style": { "font-weight": 100, color: "#0D0202" },
            "row-style": { "font-size": "14px", color: "#0D0202" },
            "header-cell-style": {
              "font-weight": "bold",
              background: "#F0F2F8",
            },
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "商品信息" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("div", { staticClass: "goods" }, [
                      _c("img", {
                        attrs: { src: row.goodsImage.split(",")[0] },
                      }),
                      _c("div", [
                        _c("p", [_vm._v(_vm._s(row.goodsName))]),
                        _c("p", { staticClass: "id" }, [
                          _vm._v(" ID：" + _vm._s(row.goodsId) + " "),
                        ]),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", { attrs: { prop: "skuName", label: "规格" } }),
          _c("el-table-column", {
            attrs: { prop: "sellCount", label: "数量" },
          }),
          _c("el-table-column", {
            attrs: { prop: "sellPrice", label: "商品单价（元）" },
          }),
          _c("el-table-column", {
            attrs: { prop: "sellTotal", label: "商品总价（元）" },
          }),
          _c("el-table-column", {
            attrs: { prop: "discountFee", label: "店铺优惠（元）" },
          }),
          _c("el-table-column", {
            attrs: { label: "商家实收（元）" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("p", [_vm._v(_vm._s(row.supplierPayment))]),
                    _c("p", { staticStyle: { color: "#736E6E" } }, [
                      _vm._v(" 含配送费：" + _vm._s(row.postFee) + " "),
                    ]),
                    _c("p", { staticStyle: { color: "#736E6E" } }, [
                      _vm._v(" 平台优惠：" + _vm._s(row.couponFee) + " "),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "订单状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.GOODS_STATUS_MAP[row.subOrderStatus] || "无")
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "售后状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.REFUND_STATUS_MAP[row.refundStatus] || "无")
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("p", { staticClass: "detail-form" }, [
        _vm._v(
          " 买家留言：" + _vm._s(_vm.tradeOrderVo.buyerMomo || "无") + " "
        ),
      ]),
      _vm.tradeOrderVo.orderType === 2
        ? _c("p", { staticClass: "header" }, [_vm._v(" 入住人信息 ")])
        : _vm._e(),
      _vm.tradeOrderVo.orderType === 2
        ? _c(
            "el-table",
            {
              attrs: {
                data: _vm.hotelData,
                border: "",
                "header-row-style": { "font-weight": 100, color: "#0D0202" },
                "row-style": { "font-size": "14px", color: "#0D0202" },
                "header-cell-style": {
                  "font-weight": "bold",
                  background: "#F0F2F8",
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "identityName", label: "入住人" },
              }),
              _c("el-table-column", {
                attrs: { prop: "mobile", label: "联系方式" },
              }),
              _c("el-table-column", {
                attrs: { prop: "identityNo", label: "身份证" },
              }),
              _c("el-table-column", {
                attrs: { prop: "personNum", label: "入住人数" },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("p", { staticClass: "header" }, [_vm._v(" 售后信息 ")]),
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.refundData,
            border: "",
            "header-row-style": { "font-weight": 100, color: "#0D0202" },
            "row-style": { "font-size": "14px", color: "#0D0202" },
            "header-cell-style": {
              "font-weight": "bold",
              background: "#F0F2F8",
            },
          },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "refundNo", label: "退款编号" },
          }),
          _c("el-table-column", {
            attrs: { prop: "applyRefundFee", label: "申请退款金额（元）" },
          }),
          _c("el-table-column", {
            attrs: { prop: "refundFee", label: "实际退款金额（元）" },
          }),
          _c("el-table-column", {
            attrs: { label: "申请时间" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    row.createTime
                      ? _c("p", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm
                                  .dayjs(row.createTime)
                                  .format("YYYY-MM-DD HH:mm:ss")
                              ) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "reason", label: "退款原因" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.REFUND_REASON_MAP[row.reason])),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "reasonDescription", label: "补充原因" },
          }),
          _c("el-table-column", {
            attrs: { label: "图片" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("div", { staticStyle: { display: "flex" } }),
                    _vm._l(row.imageUrls, function (img) {
                      return _c("el-image", {
                        key: img,
                        staticStyle: { width: "40px", height: "40px" },
                        attrs: { src: img },
                      })
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "售后状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleRefundDetail(row)
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.REFUND_STATUS_MAP[row.refundStatus]) +
                            " "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("div", { staticClass: "header" }, [
        _vm._v(" 备注 "),
        _c("span", { staticClass: "tip" }, [
          _vm._v("订单备注由商家添加，仅平台客服和商家可见"),
        ]),
      ]),
      _vm._l(_vm.remarkList, function (remark) {
        return _c("p", { key: remark.createTime, staticClass: "remark" }, [
          _vm._v(" " + _vm._s(remark.memo) + " "),
        ])
      }),
      _c("el-input", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isTipsEdit,
            expression: "isTipsEdit",
          },
        ],
        staticStyle: { "margin-bottom": "10px" },
        attrs: { type: "textarea", autosize: "", placeholder: "请输入" },
        model: {
          value: _vm.tips,
          callback: function ($$v) {
            _vm.tips = $$v
          },
          expression: "tips",
        },
      }),
      _c(
        "el-button",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isTipsEdit,
              expression: "!isTipsEdit",
            },
          ],
          attrs: { type: "primary", size: "medium" },
          on: {
            click: function ($event) {
              _vm.isTipsEdit = true
            },
          },
        },
        [_vm._v(" 添加备注 ")]
      ),
      _c(
        "el-button",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isTipsEdit,
              expression: "isTipsEdit",
            },
          ],
          attrs: { type: "primary", size: "medium" },
          on: { click: _vm.handleRemark },
        },
        [_vm._v(" 保存 ")]
      ),
      _c(
        "el-button",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isTipsEdit,
              expression: "isTipsEdit",
            },
          ],
          attrs: { size: "medium" },
          on: {
            click: function ($event) {
              _vm.isTipsEdit = false
            },
          },
        },
        [_vm._v(" 取消 ")]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }